import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/AmplifyAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import routes from './routes';
import { Amplify } from 'aws-amplify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';

Amplify.configure(window.awsconfig);

const App = () => {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <MatxTheme>
            <CssBaseline />
            {content}
          </MatxTheme>
        </LocalizationProvider>;
      </AuthProvider>
    </SettingsProvider>
  );
};

export default App;
